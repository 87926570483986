import { useEffect } from "react";

import { getQueryParams } from "../../system/helpers/urlHelper";
import FullScreenLoader from "../../components/molecules/FullScreenLoader";
import { LS_KEYS } from "../../system/constants/localStorage";
import { useOAuth } from "../../modules/Auth/api/apiHooks";
import config from "../../system/config";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../system/router/constants";

const OAuth = () => {
  const code = getQueryParams(window.location.search).get("code");
  const codeVerifier = localStorage.getItem(LS_KEYS.CODE_VERIFIER);
  const { mutate: generateToken, isPending } = useOAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      navigate(APP_ROUTES.dashboard);
    }
  }, [code, navigate]);

  useEffect(() => {
    if (code && !isPending && codeVerifier) {
      generateToken({
        grant_type: "authorization_code",
        client_id: config.DEFAULT_CLIENT_ID,
        redirect_uri: config.REDIRECT_URL,
        code_verifier: codeVerifier,
        code,
      });
    }
  }, [code, codeVerifier, isPending, generateToken, navigate]);

  return <FullScreenLoader />;
};

export default OAuth;
