const combineFilters = (...filters) => (item) => {
  return filters.map((filter) => filter(item)).every((x) => x === true);
};

export const filterBySearchAndEntity = (
  itemList,
  filterValue,
  searchValue,
  itemProps,
  isFilterByActive = false
) => {
  const {
    entityFieldName,
    activeFieldName,
    searchFieldName,
    nestedProperty = false,
    entityIsString = false,
  } = itemProps;

  const filterFn = (item, fieldName) => {
    return item[fieldName]
      ?.toString()
      .toLowerCase()
      .includes(searchValue.trim().toLowerCase());
  };

  const filterSearch = (item) => {
    if (searchValue) {
      if (Array.isArray(searchFieldName)) {
        return searchFieldName.some((property) => filterFn(item, property));
      } else {
        return filterFn(item, searchFieldName);
      }
    }

    return true;
  };

  const filterInactive = (item) => {
    if (!filterValue.checked) {
      return true;
    }

    if (isFilterByActive) {
      return !!item[activeFieldName];
    }

    return !item[activeFieldName];
  };

  const filterEntity = (item) => {
    if (filterValue.value) {
      if (entityIsString) {
        return item[entityFieldName] === filterValue.value;
      } else if (Array.isArray(item[entityFieldName])) {
        return item[entityFieldName].includes(filterValue.value);
      } else if (nestedProperty) {
        return item[entityFieldName]?.name?.toLowerCase() === filterValue.value;
      }

      return item[entityFieldName]?.hasOwnProperty(filterValue.value);
    }
    return true;
  };

  const filterBySchedule = (item, value) => {
    const { show_from, show_to } = item;
    const currentDate = new Date();
    const showFromDate = show_from && new Date(show_from);
    const showToDate = show_to && new Date(show_to);

    switch (value) {
      case "passed":
        return showToDate && currentDate > showToDate;
      case "ongoing":
        return showToDate && currentDate <= showToDate && currentDate >= showFromDate;
      case "upcoming":
        return currentDate < showFromDate;
      case "not_scheduled":
        return !showFromDate && !showToDate;
      default:
        return true;
    }
  };

  const filterMultipleEntity = (item) => {
    if (typeof filterValue === "object") {
      return Object.keys(filterValue).every((key) => {
        const { value, fieldName } = filterValue[key];
        if (fieldName === "schedule") {
          return filterBySchedule(item, value);
        }
        const itemField = item[fieldName];
        if (!value) return true;

        if (typeof itemField === "string") {
          return itemField === value;
        } else if (Array.isArray(itemField)) {
          return itemField.includes(value);
        } else if (typeof itemField === "object" && nestedProperty) {
          return itemField?.name?.toLowerCase() === value;
        } else if (typeof itemField === "boolean") {
          return itemField.toString() === value;
        }

        return itemField?.hasOwnProperty(value);
      });
    }

    return true;
  };

  return itemList?.filter(
    combineFilters(
      filterSearch,
      filterInactive,
      filterEntity,
      filterMultipleEntity
    )
  );
};

export const arrOfObjToIds = (arr) => arr?.map((el) => el.id);
export const arrOfObjToItems = (arr, field = "id") =>
  arr?.map((el) => el[field]);

export const filterArraysOfObjById = (arr1, arr2) =>
  arr1.filter((el1) => !arr2.filter((el2) => el1.id === el2.id).length);

export const filterMatchArraysOfObjById = (arr1, arr2) =>
  arr1?.filter((el1) => !!arr2?.filter((el2) => el1.id === el2.id).length);

export const filterArraysById = (arr1, arr2) =>
  arr1.filter((el1) => !arr2.filter((el2) => el1 === el2).length);

export const filterMatchValById = (arr1, arr2) =>
  arr1.filter((el1) => !!arr2.filter((el2) => el1 === el2).length);

export const updateCheckboxEntity = (values, globValues, entityId) => {
  if (typeof Object.values(values)[0] === "object") {
    const valueIds = arrOfObjToIds(values);

    return valueIds.includes(entityId)
      ? values.filter((entity) => entity.id !== entityId)
      : [...values, ...globValues.filter((entity) => entity.id === entityId)];
  } else {
    return values.includes(entityId)
      ? values.filter((id) => id !== entityId)
      : [...values, entityId];
  }
};
