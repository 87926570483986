import { Button, Divider, Dropdown } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ReactComponent as UserLogoBlue } from "../../../images/icons/header-dropdown/user-logo-blue.svg";
import { ReactComponent as ArrowDown } from "../../../images/icons/header-dropdown/arrow-down.svg";
import { headerDropdownData } from "../../../templates/constants";
import { ReactComponent as ArrowLeftIcon } from "../../../images/icons/elements/arrow-left.svg";
import { useLogout, useSSOLogout } from "../../../modules/Auth/api/apiHooks";
import { matchPath, useLocation } from "react-router-dom";
import { getCookie } from "../../../system/helpers/cookies";
import { disableTitle, isNumeric, popFromUrl } from "./constants";
import AntBtn from "../../atoms/AntBtn";
import { APP_ROUTES } from "../../../system/router/constants";
import { ApplicationState } from "../../../system/reducers";
import { RoleWrapper } from "../../../system/roles/RoleWrapper";
import { Action, Alias, Group } from "../../../system/roles/constants";
import { sidebarData } from "../Sidebar/constants";
import { usePermissionAccess } from "../../../system/hooks/usePermissionAccess";
import { usePageNavigate } from "../../../system/hooks/usePageNavigate";

const AntPageHeader: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { sso_token, name: username } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const { mutateAsync } = useLogout();
  const { mutate: logoutFromSSO } = useSSOLogout();

  const logout = async () => {
    // TODO: remove after successfull SSO intergation
    if (sso_token) {
      await logoutFromSSO();
    } else {
      await mutateAsync(getCookie("token"));
    }
  };

  const headerTitle = () => {
    const menuItems = sidebarData().filter((item) => !item.subData);
    const subMenuItems = sidebarData().filter((item) => !!item.subData);

    const filter = (data) =>
      data.filter((item) => matchPath(item.route, currentPath))[0]?.label;

    const label = filter(menuItems);
    const subLabel = filter(subMenuItems[0].subData);

    return !!label ? label : subLabel;
  };

  const isRolesRoute = currentPath.includes("roles");
  const isAccountRoute = currentPath.includes("account");
  const isAccountsRoute = currentPath.includes("accounts");
  const isCalendarsNestedRoute = currentPath.includes("/calendars/");
  const isCustomersNestedRoute = currentPath.includes("/customers/");

  const isActiveBreadcrumb = isCustomersNestedRoute;

  const titleValue =
    headerTitle() ||
    (!isNumeric(popFromUrl(currentPath)) &&
      !(popFromUrl(currentPath) === "new") &&
      !(popFromUrl(currentPath) === "roles") &&
      !isCalendarsNestedRoute &&
      popFromUrl(currentPath));

  const goBackRender = (
    <div className="history-go-back">
      <ArrowLeftIcon />
      {!isCustomersNestedRoute && (
        <span>
          Back
          {(isAccountRoute || isRolesRoute) && " to accounts"}
          {isCalendarsNestedRoute && " to calendars"}
        </span>
      )}
    </div>
  );

  const { crumbsRoutes, renderCrumbs, navigateBack } = usePageNavigate({
    lastCustomCrumb: location?.state?.username,
  });

  const createAccountBtn = isAccountsRoute && [
    <RoleWrapper
      key={1}
      hasAccess={{
        group: [Group.Authorization],
        alias: [Alias.Role],
        action: [Action.index],
      }}
    >
      <AntBtn
        text="Roles"
        type="default"
        className="btn-secondary"
        linkTo={APP_ROUTES.roles}
      />
    </RoleWrapper>,
    <RoleWrapper
      key={2}
      hasAccess={{
        group: [Group.Admin],
        alias: [Alias.Authentication],
        action: [Action.store],
      }}
    >
      <AntBtn
        text="+ Create admin"
        type="primary"
        linkTo={APP_ROUTES.accountCreate}
      />
    </RoleWrapper>,
    <Divider key={3} type="vertical" className="header-divider-wrapper" />,
  ];

  return (
    <>
      <div className="page-header-gap" />
      <PageHeader
        title={disableTitle(titleValue) && titleValue}
        backIcon={!titleValue && goBackRender}
        onBack={!titleValue && navigateBack}
        breadcrumb={{
          itemRender: renderCrumbs,
          items: isActiveBreadcrumb && crumbsRoutes,
        }}
        extra={[
          createAccountBtn,
          <Dropdown
            key={4}
            trigger={["click"]}
            menu={{
              className: "dropdown__nav-menu",
              items: headerDropdownData({
                username,
                logout,
                checkPermission,
                isSuperAdmin,
              }),
            }}
            placement="bottomRight"
            overlayClassName="header-dropdown-menu__overlay"
          >
            <Button className="header-dropdown-menu">
              <span className="header-dropdown-menu__user-logo">
                <UserLogoBlue />
              </span>
              <span className="header-dropdown-menu__arrow-down">
                <ArrowDown />
              </span>
            </Button>
          </Dropdown>,
        ]}
      />
    </>
  );
};

export default AntPageHeader;
